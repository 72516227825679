
import Vue from "vue";
import GooglePicker from "@/plugins/google-picker";
import AlertDialog from "@/components/AlertDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import EditDevice from "@/components/EditDevice.vue";
import { Dialog } from "@/types";
import { Device, Token, Model } from "@/store/devices/types";
import { mapActions, mapState } from "vuex";

type SpreadsheetImport = {
  name: string;
  devices: Device[];
};

type IndexedDevice = Device & {
  index: number;
};

export default Vue.extend({
  name: "Provisioning",
  components: {
    AlertDialog,
    ConfirmDialog,
    EditDevice,
    GooglePicker,
  },
  data: () => ({
    config: {
      apiKey: process.env.VUE_APP_API_KEY,
      clientId: process.env.VUE_APP_CLIENT_ID,
      scope:
        "https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/spreadsheets.readonly",
    },
    domain: "",
    token: {} as Token,
    devices: [] as IndexedDevice[],
    search: "",
    headers: [
      { text: "Hardware model", value: "hardwareModel" },
      { text: "Serial number", value: "serialNumber" },
      { text: "Attested device ID", value: "attestedDeviceId" },
      { text: "", value: "actions", sortable: false, align: "right" },
    ],
    tokenError: "",
  }),
  computed: {
    ...mapState("devices", ["models"]),
    isDisabled(): boolean {
      return (
        !this.domain ||
        !this.token.customerId ||
        !this.token.devicePreProvisioningToken ||
        this.devices.length < 1
      );
    },
  },
  methods: {
    ...mapActions("devices", [
      "preProvisionDevices",
      "validatePreProvisioningToken",
    ]),
    showDetails(response: Promise<SpreadsheetImport>): void {
      const errorDialog = this.$refs.errorDialog as Dialog;
      response
        .then((response: SpreadsheetImport) => {
          const hasCorrectHardwareModels = response.devices.every(
            (device: Device) =>
              this.models.some(
                (model: Model) => model.hardwareModel === device.hardwareModel
              )
          );
          if (hasCorrectHardwareModels) {
            this.devices = response.devices.map((device, index) => ({
              ...device,
              index,
            }));
          } else {
            errorDialog.open({
              body: "hardwareModel",
            });
          }
        })
        .catch(() => {
          errorDialog.open({
            body: "spreadsheetFormat",
          });
        });
    },
    provision(): void {
      const successDialog = this.$refs.successDialog as Dialog;
      const errorDialog = this.$refs.errorDialog as Dialog;
      this.validatePreProvisioningToken(this.token)
        .then(() => {
          const devices: Device[] = this.devices.map((d) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { index, ...device } = d;
            return device;
          });
          this.preProvisionDevices({
            domain: this.domain,
            token: this.token,
            devices,
          })
            .then(() => {
              successDialog.open();
            })
            .catch((err) => {
              errorDialog.open();
              console.log(err);
            });
        })
        .catch(() => {
          errorDialog.open({
            body: "preProvisioningToken",
          });
          this.tokenError = "Invalid token.";
        });
    },
    saveDevice(device: IndexedDevice): void {
      if (device.index > -1) {
        this.devices = this.devices.map((d) =>
          d.index === device.index ? device : d
        );
      } else {
        device.index = this.devices.reduce((acc, cur) => {
          return cur.index >= acc ? cur.index + 1 : acc;
        }, 0);
        this.devices.push(device);
      }
    },
    removeDevice(index: number): void {
      this.devices = this.devices.filter((d) => d.index !== index);
    },
  },
});
