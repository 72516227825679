import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"edit-user"},[_c(VDialog,{attrs:{"max-width":"580px"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c(VAutocomplete,{attrs:{"items":_vm.models,"item-text":"hardwareModel","label":"Hardware model","rules":[(v) => !!v || 'Item is required'],"required":""},model:{value:(_vm.device.hardwareModel),callback:function ($$v) {_vm.$set(_vm.device, "hardwareModel", $$v)},expression:"device.hardwareModel"}}),_c(VTextField,{attrs:{"label":"Serial number","rules":[(v) => !!v || 'Item is required'],"required":""},model:{value:(_vm.device.serialNumber),callback:function ($$v) {_vm.$set(_vm.device, "serialNumber", $$v)},expression:"device.serialNumber"}}),_c(VTextField,{attrs:{"label":"Attested device Id","rules":[(v) => !!v || 'Item is required'],"required":""},model:{value:(_vm.device.attestedDeviceId),callback:function ($$v) {_vm.$set(_vm.device, "attestedDeviceId", $$v)},expression:"device.attestedDeviceId"}})],1)],1),_c(VCardActions,{staticClass:"pb-4 px-6"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"grey darken-2"},on:{"click":function($event){return _vm.close('cancel')}}},[_c(VIcon,[_vm._v("mdi-close")]),_c('span',[_vm._v("Cancel")])],1),_c(VBtn,{attrs:{"text":"","disabled":!_vm.validForm,"color":"primary"},on:{"click":function($event){return _vm.close('save')}}},[_c(VIcon,[_vm._v("mdi-content-save-outline")]),_c('span',[_vm._v("Opslaan")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }