
import Vue from "vue";
import { Device } from "@/store/devices/types";
import { mapState } from "vuex";

export default Vue.extend({
  name: "EditUser",
  data: () => ({
    visible: false,
    device: {} as Device,
    validForm: false,
    title: "",
  }),
  computed: {
    ...mapState("devices", ["models"]),
  },
  methods: {
    open(device: Device): void {
      if (this.$refs.form) {
        (this.$refs.form as HTMLFormElement).resetValidation();
      }
      this.visible = true;
      this.device = { ...device };
      this.title = device.serialNumber ? "Edit device" : "Add device";
    },
    close(event: string): void {
      this.visible = false;
      this.$emit(event, this.device);
    },
  },
});
