/* eslint-disable no-undef, no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  template:
    '<button @click="handleButtonClick" :disabled="!pickerApiLoaded"><slot/></button>',
  props: ["config"],
  data() {
    return {
      picked: {},
      pickerApiLoaded: false,
      oauthToken: "",
    };
  },
  methods: {
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
        gapi.load("client", () => {
          gapi.client.load("sheets", "v4");
        });
      } else {
        return console.warn(authResult.details);
      }
    },
    handleButtonClick() {
      gapi.auth2.authorize(
        {
          client_id: this.config.clientId,
          scope: this.config.scope,
        },
        this.handleAuthResult
      );
    },
    createPicker() {
      if (this.pickerApiLoaded && this.oauthToken) {
        const pickerBuilder = new google.picker.PickerBuilder()
          .setOAuthToken(this.oauthToken)
          .setDeveloperKey(this.config.apiKey)
          .setCallback(this.pickerCallback);

        this.$emit("build", pickerBuilder);

        if (!this.$listeners.build) {
          pickerBuilder.addView(google.picker.ViewId.SPREADSHEETS);
        }

        const picker = pickerBuilder.build();
        picker.setVisible(true);
      }
    },
    pickerCallback(data) {
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        this.$emit(
          "picked",
          new Promise((resolve, reject) => {
            gapi.client.sheets.spreadsheets.values
              .get({
                spreadsheetId: data.docs[0].id,
                range: "A1:C",
              })
              .then(
                (response) => {
                  try {
                    const range = response.result;
                    const result = {
                      name: data.docs[0].name,
                      devices: [],
                    };
                    const headers = range.values[0]
                      .map((h) => h.replace(/[\s-]/g, "").toUpperCase())
                      .reduce((acc, cur, i) => {
                        switch (cur) {
                          case "SERIALNUMBER":
                            acc.serialNumber = i;
                            return acc;
                          case "HARDWAREMODEL":
                            acc.hardwareModel = i;
                            return acc;
                          case "ATTESTEDDEVICEID":
                            acc.attestedDeviceId = i;
                            return acc;
                          default:
                            reject("Error: Bad header detected");
                        }
                      }, {});
                    for (let i = 1; i < range.values.length; i++) {
                      const row = range.values[i];
                      result.devices.push({
                        serialNumber: row[headers.serialNumber],
                        hardwareModel: row[headers.hardwareModel],
                        attestedDeviceId: row[headers.attestedDeviceId],
                      });
                    }
                    resolve(result);
                  } catch (err) {
                    reject("Error: " + err);
                  }
                },
                (response) => {
                  reject("Error: " + response.result.error.message);
                }
              );
          })
        );
      }
    },
  },
  mounted() {
    if (!gapi) return console.warn("Google API not loaded");
    gapi.load("auth2");
    gapi.load("picker", () => {
      this.pickerApiLoaded = true;
    });
  },
};
